.wrapper {
    background: linear-gradient(180deg,#182384 0%,#000 100%);
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.iconWrapper {
    width: 100%;
    height: 50vh;
    max-height: 500px;
    text-align: center;
    border-style: none;
}

.iconWrapper img {
    max-height: 100%;
    max-width: 100%;
}