@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Oswald:wght@400;500&display=swap');

.wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background:rgb(15,33,85);
    background: linear-gradient(180deg,rgba(17,30,104,1) 0%, rgba(14,19,39,1) 100%);
    overflow: hidden;
    font-size: clamp(0.8rem, 0.4293rem + 1.6452vw, 1.3rem);
}

.visualizerWrapper {
    width: 100%;
    height: 100%;
}

.labelWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;
}