.outerWrapper {
    background: linear-gradient(180deg, #121869 0%, #0E1327 100%);
    min-height: 100vh;
    overflow-x: hidden;
}

.menuWrapper {
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 40px;
    z-index: 10;
}

.menuButton {
    flex: 0 1 auto;
    margin-left: 25px;
    height: 30px;
    cursor: pointer;
}

.contentWrapper {
    width: 90vw;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 140px;
}

.teaserWrapper {
    width: 50%;
    height: 60vh;
}

.singleColumnParagraph {
    flex: 0 1 auto;
    width: 50%;
    min-width: 350px;
}

.singleColumnParagraph section {
    margin: 0;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: clamp(0.9rem, 0.5147rem + 0.8226vw, 1.4rem);
    line-height: 1.5em;
    padding-right: 20px;
    padding-left: 20px;
}

.titleColumn {
    flex: 0 1 auto;
    width: calc(50% - 1px);
    min-width: 349px;
}

.teaserWrapper img {
    max-width: 100%;
    max-height: 100%;
}

.title {
    margin: 0;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: clamp(2rem, 0.5733rem + 4.1131vw, 3rem);
    text-transform: uppercase;
    line-height: 1.25;
    padding: 0px 20px 20px 20px;
    padding-left: 20px;
}

.mainTitleTopOffset {
    margin-top: 20vh;
}

.titlePadding {
    padding-right: 20px;
    padding-left: 20px;
}

.subtitle {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 300;
}

.titledParagraphWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.worldContentWrapper {
    position: relative;
    width: 90vw;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 4em;
    height: calc(100vh - 300px);
    padding-top: 140px;
    overflow: hidden;
}

.teaser {
    height: 25vh;
}

.teaser img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.gridFullRowElement {
    grid-column: 1 / -1;
}

.worldColumnWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    height: calc(100vh - 300px);
}

.scrollableTextWrapper {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: scroll;
}

.scrollableTextWrapper section {
    margin: 0;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: clamp(0.9rem, 0.5147rem + 0.8226vw, 1.4rem);
    line-height: 1.5em;
    padding-right: 20px;
    padding-left: 20px;
}

.cite {
    font-style: italic;
    padding: 25px;
}

.fullRowElement {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.overflowImage {
    position: relative;
    width: 300%;
    left: -100%;
    max-height: 130vh;
    object-fit: contain;
}

.center {
    text-align: center;
}

.fullRowImage {
    max-width: 100%;
    max-height: 100%;
    opacity: 0.7;
}

.captionedImageWrapper {
    display: table;
    max-width: 100%;
    width: auto;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 50px;
}

.captionedImageWrapper img {
    display: block;
    max-width: 100%;
    max-height: 90vh;
}

.caption {
    display: table-caption;
    caption-side: bottom;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-style: italic;
    text-align: left;
    padding-top: 20px;
    line-height: 1.25;
}

.leftWhiteBorder {
    border-left: 1px solid white;
}

.rightWhiteBorder {
    border-right: 1px solid white;
}

.inlineFormula {
    font-size: 0.85em;
}

.worldComic {
    position: absolute;
    width: 66%;
    height: calc(100vh - 350px);
    top: 140px;
    left: 33%;
    text-align: center;
}

.worldComic img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.moveComic {
    animation-name: comicTranslation;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

.fadeText {
    animation-name: fadeTextIn;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

@keyframes fadeTextIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes comicTranslation {
    from   {
        width: 66%;
        top: 140px;
        left: 33%;
        height: calc(100vh - 350px);
    }
    to {
        width: 30%;
        top: 60%;
        left: 0%;
        height: 40%;
    }
  }

.hidden {
    visibility: hidden;
}

.scrollArrow {
    height: 40px;
    position: absolute;
    bottom: -40px;
    left: 25%;
    transform: translate(-50%, 0%) rotate(90deg);
}

.worldPortraitWrapper {
    width: 90vw;
    margin: 0 auto;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: clamp(0.9rem, 0.5147rem + 0.8226vw, 1.4rem);
    line-height: 1.5em;
    padding-top: 100px;
}

.worldPortraitWrapper section {
    margin: 0;
    margin-bottom: 20px;
}

.worldPortraitImageWrapper {
    width: 100%;
    max-height: 100vh;
    margin: 20px 0;
}

.worldPortraitImageWrapper img {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 600px) {
    .menuButton {
        height: 25px;
    }

    .menuWrapper {
        margin-right: 15px;
    }

    .teaserWrapper {
        width: 40%;
        height: inherit !important;
    }

    .mainTitleTopOffset {
        margin-top: 10vh;
    }

    .caption {
        font-size: clamp(.85rem,.5147rem + .8226vw,1.4rem);
    }

    .title {
        line-height: 1.3em;
    }
}