.fullHeightWrapper {
    position: relative;
    height: 100vh;
}

.contentBox {
    height: 100%;
    max-width: 1000px;
    width: 90vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.introTeaser {
    flex: 0 1 auto;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-size: 34px;
}

.quickNavWrapper {
    position: absolute;
    width: 5px;
    height: 40vh;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 40px;
    left: 5vw;
}

.quickNavButton {
    flex: 0 1 auto;
    width: 100%;
    height: 21.5%;
    background-color: white;
    opacity: 1;
}

.quickNavButton:hover {
    cursor: pointer;
}

.shortTextWrapper {
    flex: 0 1 auto;
    display: flex;
    min-height: 200px;
    align-items: center;
}

.shortText {
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: clamp(1.4rem,.5rem + 1vw,1.8rem);
    line-height: 1.25;
    opacity: 0;
}

.shortTextTitle {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    padding-right: 10px;
    text-transform: uppercase;
}

.visualiser {
    position: relative;
    width: 100%;
    flex: 1 1 auto;
    font-size: clamp(0.8rem, 0.5147rem + 0.8226vw, 1.4rem);
    pointer-events: none;
}

.introAnimationCanvas {
    position: absolute;
    width: 100%;
    height: 90%;
    top: 10%;
}

.inactive {
    opacity: 0.5;
}

.fadeIn {
    animation: fadeIn 0.75s forwards;
}

.fadeOut {
    animation: fadeOut 0.25s forwards;
}
  
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 600px) {
    .quickNavWrapper {
        transform: scale(0.6);
        transform-origin: top;
    }

    .shortTextWrapper {
        align-items: flex-start;
    }
}