.minorLinkBox {
    color: white;
    width: 100%;
    border-top: 1px solid white;
    text-transform: uppercase;
    font-size: clamp(0.9rem, 0.5147rem + 0.8226vw, 1.4rem);
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
}

.minorLink {
    flex: 1 1 auto;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    text-decoration: none;
    text-transform: uppercase;
}

.minorLink:visited {
    color: white;
}

.black {
    color: black;
    border-top: 1px solid black;
}