.app {
    position: absolute;
    width: 100vw;
    height: 100vh;
    color: white;
}

.background {
    position: fixed;
    width: 100vw;
    height: 700vh;
    background: rgb(217,213,240);
    background: linear-gradient(180deg,#70c3ff,#9494ff 8%,#ff9873 56%,#744291 70%,#182384 100%);
    overflow: hidden;
}

.contentWrapper {
    position: relative;
    width: 80vw;
    max-width: 1000px;
    margin: 0 auto;
}

.introAnimationWrapper {
    width: 100%;
    margin: 0 auto;
    padding-top: 10vh;
    height: 55vh;
}

.introAnimationCanvas {
    width: 100%;
    height: 100%;
}

.introText {
    max-width: 600px;
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: clamp(0.8rem, 0.2000rem + 2.6667vw, 1.4rem);
    text-align: left;
    line-height: 1.5em;
    padding-bottom: 20vh;
}

.footerWrapper {
    position: relative;
    padding-top: 10vh;
}

@media only screen and (max-width: 600px) {
    .introText {
        padding: 0 0 5vh;
    }
}
