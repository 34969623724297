.wrapper {
    min-height: 100vh;
    width: 100vw;
    background-color: #E4E4E4;
    color: black;
}

.contentColumn {
    width: 90vw;
    max-width: 600px;
    margin: 0 auto;
}

.topNavMenuWrapper {
    position: fixed;
    display: flex;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 32px;
    top: 0;
    padding-top: 10vh;
    padding-bottom: 20px;
    background-color: #E4E4E4;
    width: 600px;
    z-index: 1;
}

.topNavMenuEntry {
    margin-right: 20px;
    cursor: pointer;
}

.title {
    position: fixed;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    top: calc(10vh + 60px);
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 32px;
    text-transform: uppercase;
    margin: 0;
    background-color: #E4E4E4;
    width: 600px;
    z-index: 1;
}

.textWrapper {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding-top: calc(10vh + 175px);
    line-height: 1.5em;
}

.textWrapper section {
    margin: 0;
    padding: 0 0 15px 0;
}

.textWrapper h2 {
    margin: 0 0 20px;
}

.textWrapper a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    background: black;
    border-radius: 20px;
    color: white;
    padding: 10px 25px;
    font-size: 20px;
    cursor: pointer;
}

.active {
    font-weight: 400;
}

.center {
    text-align: center;
    margin-top: 10px !important;
}