.teamListWrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.memberWrapper {
    flex: 0 1 auto;
    width: 280px;
    max-width: 100%;
    padding: 10px;
}

.portrait {
    width: 100%;
    height: auto;
}

.imageCaptionWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    cursor: pointer;
}

.title {
    margin: 5px 0 0 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1em;
}

.subtitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    vertical-align: top;
}

.plus {
    flex: 0 1 auto;
    font-size: 2rem;
    transition-duration: 0.5s;
    transition-property: transform;
}

.hidden {
    display: none;
}

.detailText {
    margin-top: 20px;
    font-size: 1rem;
    line-height: 1.25rem;
}