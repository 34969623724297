.labelWrapper {
    position: absolute;
    width: 100%;
    height: 90%;
    top:10%;
}

.label {
    position: absolute;
}

.columnVector {
    transform: translate(-50%, -50%);
}

.clickable {
    cursor: pointer;
    pointer-events: all;
}

.hidden {
    display: none;
}

.fadeIn {
    animation: fadeIn 2s forwards;
}

.fadeOut {
    animation: fadeOut 1s forwards;
}
  
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: initial;
    }
    100% {
        opacity: 1;
    }
}

.topRight {
    transform: translate(5px, calc(-100% - 5px));
}

.topLeft {
    transform: translate(calc(-100% - 5px), calc(-100% - 5px));
}

.bottomRight {
    transform: translate(5px, 5px);
}

.bottomLeft {
    transform: translate(calc(-100% - 5px), 5px);
}