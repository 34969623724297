.backgroundWrapper {
    padding-top: 30px;
    padding-bottom: 30px;
}

.contentWrapper {
    width: 90vw;
    max-width: 600px;
    margin: 0 auto;
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: clamp(0.9rem, 0.5147rem + 0.8226vw, 1.4rem);
    line-height: 1.5em;
}

.title {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    margin: 40px 0;
    line-height: 1em;
    font-size: clamp(2rem, 0.5733rem + 4.1131vw, 3rem);
    text-transform: uppercase;
}

.minorTitle {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    margin-bottom: 10px;
    font-size: clamp(0.9rem, 0.5147rem + 0.8226vw, 1.6rem);
    font-weight: 500;
}

.logo {
    max-width: 50%;
    display: block;
    margin: 0 auto;
}

.foto {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 75vh;
    margin: 20px 0;
}