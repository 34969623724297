.footerFlexBox {
    display: flex;
    flex-direction: column;
    width: 90vw;
    max-width: 1000px;
    margin: 0 auto;
    justify-content: space-evenly;
}

.modeSwitchBox {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px;
    background: rgba(255,255,255,0.2);
    border-radius: 20px;
    margin-top: 20vh;
}

.cubeIconWrapper {
    flex: 0 1 auto;
    position: relative;
    padding: 30px;
    width: 25vw;
    min-width: 300px;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cubeIconWrapper img {
    width: 100%;
    height: 100%;
}

.exploreDescription {
    flex: 1 1 auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: clamp(0.9rem, 0.5147rem + 0.8226vw, 1.4rem);
    margin-left: 20px;
    margin-right: 20px;
}

.exploreDescription span {
    padding: 50px 0;
}

.modeSwitchLink {
    color: white;
    font-size: clamp(1rem, 0.2866rem + 2.0566vw, 2rem);
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    padding: 20px;
    background: rgba(255,255,255,0.5);
    cursor: pointer;
    border-radius: 100px;
    text-align: center;
    box-shadow: 0 0 20px -5px rgb(245,155,126);
    margin-bottom: 40px;
}

.modeSwitchLink:visited {
    color: white;
}