@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;1,300&display=swap&family=Oswald:wght@200;400;500&display=swap');

:root {
  touch-action: pan-x pan-y;
}

body {
  background-color: #666666;
  font-size:16px;
  overflow-x: hidden;
}
section {
  margin: 1em;
}

sup {
  line-height: 0;
  font-size: 0.5em;
  vertical-align: super;
}

a {
  color: white;
  text-decoration: none;
  border-bottom: 1px dashed white;
}
a:visited {
  color: white;
}

.app-body {
  font-family: 'Arial', sans-serif;
  font-size: 100%;
  color: white;
}

.box-header {
  flex: 0 1 auto;
  padding: 0.25em 0.75em 0.25em;
  background-color: #333333;
}

.box-row-fill {
  flex: 1 1 auto;
}

.arrow-back {
  width: 1em;
}
.general-box-content {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.cop-explorer-mount {
  flex: 1 1 auto;
}

.cop-explorer-footer {
  flex: 0 1 3em;
  text-align: center;
}
.cop-button {
  background-color: #222222; /* Green */
  border: none;
  color: white;
  padding: 0.5em;
  font-size: 0.75em;
}

.cop-title {
  margin: 0;
  flex: 0 1 4em;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 1em;
  padding-top: 0.5em;
}

.home-content {
  text-align: center;
  color: white;
}

.home-content br {
  line-height: 4rem;
}

.home-content a {
  color: white;
  text-decoration: none;
}

.home-background {
  position: relative;
  width: 100%;
  height: 100%;
}

.home-title {
  text-align: center;
  color: white;
}

.home-title span {
  border-bottom: 2px solid white;
  border-top: 2px solid white;
  padding: 0.25em 0.75em 0.25em;
}