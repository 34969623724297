
.titleWrapper {
    padding: 0;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 15vh;
}

.mainTitle {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: clamp(2.5rem, 0.7166rem + 5.1414vw, 5rem);
    text-transform: uppercase;    
    margin: 0;
}

.subTitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: clamp(1rem, 0.4293rem + 1.6452vw, 1.8rem);
    margin: 0;
}

.scrollArrow {
    height: 40px;
    margin-top: 20px;
    transform: rotate(90deg);
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .scrollArrow {
        height: 30px;
    }

    .titleWrapper {
        padding: 0 0 5vh;
    }
}