.topMenuWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    pointer-events: none;
}

.menuOpenOverlay {
    width: 100%;
    height: 100%;
    background: #000749a1;
    pointer-events: all;
}

.menuBox {
    position: absolute;
    right: 30px;
    top: 30px;
    bottom: 0;
    width: 400px;
    font-size: 32px;
    text-align: right;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    text-shadow: rgba(0, 0, 0, 0.3) 2px 2px 5px;
}

.menuIcon {
    position: absolute;
    right: 0px;
    height: 30px;
    max-width: 7vw;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
    pointer-events: all;
    cursor: pointer;
}

.menuIconEnlarged {
    filter: drop-shadow(1px 1px 3px rgba(79, 79, 79, 0.26));
    height: 45px;
    max-width: 7vw;
}

.menuList {
    padding-top: 60px;
    list-style-type: none;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    max-height: 80%;
    pointer-events: all;
}

.menuList::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.menuListEntry {
    text-transform: uppercase;
    text-decoration: none;
    padding-top: 15px;
    color: white;
}

.menuListEntry:visited {
    color: white;
}

.menuListEntry:hover {
    text-decoration: underline;
    text-decoration-style: dotted;
    color: white;
    cursor: pointer;
}

.expandingListEntry {
    text-transform: uppercase;
    text-decoration: none;
    padding-top: 15px;
    color: white;
}

.topicList {
    list-style-type: none;
}

.topicListTitle {
    list-style: none; 
    cursor: pointer
}

.topicListArrow {
    width: 15px;
    height: auto;
    margin-left: 15px;
    margin-right: 10px;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
}

.topicListEntry {
    text-transform: none;
    padding-top: 5px;
    padding-right: 40px;
    font-size: 24px;
    opacity: 0.75;
    cursor: pointer;
    font-weight: 300;
}

.topicListEntry:hover {
    text-decoration: underline;
    text-decoration-style: dotted;
    color: white;
}

.languageButtonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: center;
    font-size: 24px;
    pointer-events: all;
}

.languageButton {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height: 50px;
    width: 50px;
    border: 2px solid white;
    border-radius: 200px;
    margin-left: 20px;
    cursor: pointer;
}

.hidden {
    display: none;
    pointer-events: none;
}

.active {
    background-color: rgba(255,255,255,0.3);
}