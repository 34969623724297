.topRight {
    transform: translate(10px, -100%);
}

.rightHalfTop {
    transform: translate(10px, -50%);
}

.topLeft {
    transform: translate(calc(-100% - 10px), calc(-100% - 10px));
}

.bottomRight {
    transform: translate(10px, 10px);
}

.bottomLeft {
    transform: translate(calc(-100% - 10px), 10px);
}

.lightLabel {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 0.65em;
    line-height: 1.2em;
}

.boldLabel {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 1.6em;
    line-height: 1.2em;
}

.rightNoWrap {
    text-align: right;
    white-space: nowrap;
}

.padding {
    padding: 25px;
}